.css-scroll {scrollbar-color: $dark $gray-300; scrollbar-width: thin;
	&::-webkit-scrollbar {width: 8px; background-color: $gray-300;} 
	&::-webkit-scrollbar-thumb {background-color: $dark; border-radius:10px;}
	&::-webkit-scrollbar-thumb:hover {background-color: $dark; border:1px solid $dark;}
	&::-webkit-scrollbar-thumb:active {background-color: $dark; border:1px solid $dark;} 
}
.padding-5 {margin-left: -5px; margin-right: -5px;
	[class*="col"] {padding-left: 5px; padding-right: 5px;}
}
.lazy-load-image-background {width: 100% !important;}
.MuiSkeleton-root {background-color: rgba($black, 0.11) !important;}
.minH-250 {min-height: 250px;}
.share-button {text-align: left;}
.body-bg {background-color: $body-bg !important;}
.MuiInput-root {width: 100%;}

.dropdown-notification {
	.notify-loading {display: flex; align-items: center; justify-content: center; min-height: 200px;}
    .scrolldiv .request {max-height: 220px; overflow-x: hidden; overflow-y: auto;}
    .dropdown-toggle {cursor: pointer; position: relative;
		&:after {content: none;}
		img {transform: scale(1.4);}
		.badge {position: absolute; padding: 0.50em 0.6em; top: 0px; right: 0px; border-radius: 10px;}
	}
    .dropdown-menu {min-width: 330px; margin-top: 8px; border-radius: 2px; border: none; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); left: auto !important; right: 0px; position: absolute;
        .dropdown-arrow {position: absolute; bottom:100%; left: auto; right: -8px; overflow: hidden; height:22px; width:50px;
            &:after {content:''; transform: rotate(45deg); background: $white; position: absolute; top: 16px; right: 15px; width: 25px;height: 25px; border-radius: .25rem; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);}
        }
        .dropdown-header {border-bottom: 1px solid rgba(0,0,0,.1); padding: 8px 0px 6px; line-height: 22px;
            .font-18 {font-size: 16px;}
        }
    }
    .timeline {position: relative; padding: 0; list-style: none; padding-left: 0px;
        .list-group {
			.list-group-item {padding: 5px; cursor: pointer; line-height: 18px;
				&:first-child {border-top: none;}
				&:hover {background-color: lighten($gray-300, 57%) !important;}
				&.active {background-color: lighten($gray-300, 57%) !important; border-color: rgba($black, 0.125) !important; color: inherit;}
				.image {margin-right: 6px; width: 40px; float: left;}
				.caption {width: calc(100% - 0px); font-size: 12px}
				.text {font-size: 11px; font-weight: 500;}
			}
		}
	}
	.scroller {max-height: 200px; min-height: 200px;}
    .scroll {height: 100%; max-height: 200px; overflow: auto;}
    @media(max-width: 600px) {
      .dropdown-menu {min-width: 310px; right: -133px;
        .dropdown-arrow {right: 128px;}
      }
    }
} // Notification dropdown css end

.card-profile {
	.card-body {padding: 0px;}
	figure {margin-bottom: 20px;
		.pic {position: relative;
			img {height: 140px;}
		}
		.btn-upload {position: absolute; cursor: pointer; margin: 0px; left: 50%; bottom: -10px; font-size: 20px; padding: 0px 8px; margin-left: -20px; z-index: 9;
			input {width: 1px; height: 1px; visibility: hidden;}
		}
	}
} // Public Profile card css end

.availibility-page {
	.MuiFormControl-root {width: 100%;}
	h2 {padding-bottom: 15px; font-size: 28px; font-weight: bold; color: $black;
		small {font-weight: bold; font-size: 20px; color: lighten($black, 30%);}
	}
	.list {
		h3 {font-weight: bold; font-size: 18px;
			.day{color:$primary;font-weight: bold;font-size: 20px;}
			button {padding: 6px; color: $white; background-color: $primary;
				+ button {margin-left: 8px;}
			}
		}
		.availability {border-bottom: 1px solid $gray-200;padding-bottom: 20px;}
		.box {width: 100%;
			.form {width: calc(100% - 150px);}
			.action {width: 150px; text-align: center; padding-top: 20px;
				button {padding: 6px;
					+ button {margin-left: 8px;}
					&.submit { color: $white; background-color: $info; padding: 5px 10px;}
				}
			}
		}
	}
	@media(max-width: 767px) {
		.list {
			.box {
				.form {width: 100%;}
			}
		 }
	}
} // User Availability css end

.loader {position: absolute; left: 0px; border-radius: 0%; width: 100%; height: 100%; display: flex; top: 0px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.7);
    span { position: absolute; display: inline-block; width: 50px; height: 50px; border-radius: 100%; background:rgba(135, 211, 124,1); -webkit-animation: loader3 1.5s linear infinite; animation: loader3 1.5s linear infinite;
        &:last-child { animation-delay:-0.9s; -webkit-animation-delay:-0.9s;}
    }
 }
 @keyframes loader3 {
    0% {transform: scale(0, 0);opacity:0.8;}
    100% {transform: scale(1, 1);opacity:0;}
 }
 @-webkit-keyframes loader3 {
    0% {-webkit-transform: scale(0, 0);opacity:0.8;}
    100% {-webkit-transform: scale(1, 1);opacity:0;}
 } // Profile Loading css end
 .confirm-dialog {
	.MuiDialog-paperWidthSm {max-width: 400px; border-radius: 12px;}
	.MuiDialogTitle-root {padding: 10px 20px;}
	.MuiDialogContent-root {padding: 8px 20px;}
	.MuiDialogContentText-root {margin-bottom: 0px; font-weight: 600; color: $black;}
	.MuiTypography-h6 {font-weight: 600;}
	.btn {border-radius: 8px; font-size: 15px; padding: 6px 20px; font-weight: 500;}
	
} // Confirm Dialog css end

.sub-heading {
	h3 {font-weight: 600;}
}

.react-confirm-alert-overlay {background: rgba($black, 0.5);}
.react-confirm-alert-body {padding: 20px; font-size: 15px; font-weight: 600; color: $black;
	@media (max-width: 375px) {
		width: 305px;
	}
}
@media (max-width: 375px) {
	.react-confirm-alert-body > h1 {font-size: 20px !important;}
}
.react-confirm-alert-button-group {display: flex; align-items: center; justify-content: center;
	button {padding: 6px 26px; font-size: 14px; margin-right: 0px; margin-left: 10px; background-color: $gray-100; color: $black;
		&:last-child {background-color: $primary; color: $white;}
	}
} // Corform alert

 .booking-dialog {
	.MuiDialogContent-root {overflow-y: scroll;height: 500px;}
	.closeButton {position: absolute; right: 5px; top: 8px; color: $white;}
	.MuiDialog-paperWidthSm {min-width: 520px;}
	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {width: 1.9rem; line-height: 1.9rem; margin: 0.266rem;}
	.heading {margin-bottom: 15px; font-weight: bold; display: flex; align-items: center; justify-content: space-between; font-size: 15px;
		.btn {border-radius: 8px; font-size: 12px;
			.fa-calendar-alt {margin-right: 4px; transform: scale(1.3);}
			.fa-angle-right {margin-left: 4px; transform: scale(1.6);}
		}
	}
	.slots {margin-bottom: 10px;
		ul {padding: 0px; margin: 0px -5px; list-style: none; display: flex; align-items: center; flex-wrap: wrap;
			li {padding: 0px 5px; margin-bottom: 10px; cursor: pointer;
				.badge-secondary {background-color: #DFE3FF !important;}
				&.active .badge {background-color: $info !important; color: $white;}
			}
			.badge {border-radius: 8px; background-color: #EEEEEE !important; font-size: 13px; font-weight: 400; padding: 12px 15px;width:170px;}
		}
	}
	.card-details {margin-top:20px;
		label{color:$info;}
		h3 {font-size: 18px; font-weight: 700; padding-bottom: 15px;border-bottom:1px solid $gray-200;margin-bottom: 20px;text-transform: uppercase;}
	}
	@media (max-width: 1200px) {
		.MuiDialog-paperWidthSm {
			width: 100%;
			min-width: auto;
		}
	}
} // Booking dialog css end

.react-datepicker {border-radius: 0px !important; border: none !important;
	.react-datepicker__header {background-color: transparent !important; border-bottom: none !important; padding-top: 0px !important; border-radius: 0px !important;
		.react-datepicker__current-month {background-color: #F6921E; color: $white; padding: 8px;}
		.react-datepicker__day-names {border-left: 1px solid $gray-200; border-right: 1px solid $gray-200;}
	}
	.react-datepicker__month {border: 1px solid $gray-200; margin: 0px; padding: 0.4rem;}
	.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {background-color: #EC297B;
		&:hover {background-color: darken(#EC297B, 10%);}
		&:focus {outline: none;}
	}
} // Date picker css end

.zipcode-dialog {
	.closeButton {position: absolute; right: 5px; top: 3px; color: $white;}
	.heading {background-color: $info; color: $white; padding: 10px 24px;}
	.MuiDialogActions-root {justify-content: flex-start;padding: 8px 22px;}
	.input-box {padding:0px !important;height: auto !important;
		.MuiInput-underline .MuiInputBase-input {
		padding: 10px 10px 7px !important;
		}
	}
}
.custom-dialog {
	.closeButton {position: absolute; right: 5px; top: 3px; color: $white;}
	.MuiDialog-paperWidthSm {min-width: 520px; border-radius: 8px;}
	.heading {background-color: $info; color: $white; padding: 10px 24px;}
	.btn {padding: 8px 24px; }
	.btn-secondary {background-color: $gray-100 !important; border-color: $gray-100 !important;}
	.btn-info {background-color: $info !important; border-color: $info !important; color: $white;}

	&.review {
		.MuiSvgIcon-root {fill: $black;}
		.image {
			img {border:1px solid $gray-200;padding: 5px;border-radius: 50%;}
		}
		h5 {font-size: 17px;font-weight: 600;text-decoration:none;}
		.rating { 
			span{ font-size: 14px;}
		}
		.location { color:$gray-300;font-weight:600;margin-bottom: 0px;}
		.rating-view {
			ul {list-style:none;
				li{text-align: center;
					.smile-icon {border-bottom:2px solid $gray-200;margin-bottom: 20px;padding: 30px;
						img {width: 50px;height: 50px;}
					}
					.rating-btn {text-align: center;
						.btn-rate {border:none; padding: 10px; color:$gray-200;
							span{ display: block; margin-bottom: 6px; }
							//i {color:$gray-200;}
						}
						&.active {color: $primary;
							.btn-rate {
								span {color: $primary;}
								i {color: #F6921E;}
							}
						}
					}
				}
			}
		}
		.MuiDialogActions-root {padding: 0px 23px 10px;justify-content: flex-start;}
		.MuiDialogTitle-root {padding: 0px 24px;}
	}
	@media (max-width: 1200px) {
		.MuiDialog-paperWidthSm {
			width: 100%;
			min-width: auto;
		}
	}

} // Custom Dialog css end

.StripeElement {display: block;
	max-width: 100%;
	padding: 14px 14px 10px 14px;
	height: calc(3rem + 4px);
	outline: 0;
	border-color: #e9ecef;
    border-radius: 4px;
    border: 1px solid #e9ecef;
	background: white;
}

.StripeElement--focus {
	//box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
	padding: 0;
}

#footer {background-color:#e7fbfc;border-bottom: 40px solid $info;
	h5 {font-size: 20px; margin-top: 40px;color:$info;font-weight: 700;margin-bottom: 20px;}
	.footer-menu {
		li {line-height: 38px; font-size: 15px;color:$black;
			a {color: inherit; transition: all 0.3s ease-in-out;}
			a:hover, a:focus {text-decoration: none; color: $info;}
		}
	}
	.navbar-social {margin-top:40px;
		.nav-item {
			.nav-link {color:$gray-500;
				.fa-facebook-f {padding: 7px 9px;}
				i { border-radius: 50%;padding: 7px; border:1px solid $info;background-color: $info;color:$warning;margin-right: 10px;}
			}
		}
	}
	.copyright {color:$gray-500;margin-top:30px;
		a {color:inherit;}
		i {color:$gray-500;}
	}
	.f-logo {
		img {width:121px;}
	}

	@media (max-width: 767px) {
		h5{font-size: 16px;}
		.copyright {text-align: center;margin-bottom: 10px;}
		.f-logo {text-align: center;
			img {width:105px;}
		}
	}
}

.service-box {box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.20);padding: 20px;background-color: $white;
@media (min-width: 1199px) {margin:-140px 10px 0;} margin:-140px 0px 0;border-radius:6px;
	.image { width:132px;height: 132px;background-color:$info;border-radius: 50%;display: flex;justify-content: center;align-items: center;margin: auto;
		i {background-color:$info;color:$warning;padding:12px 14px;border-radius: 50%;font-size: 20px;}
		i.fa-dollar-sign {padding:10px 20px;}
	}
	.caption {
		h5 {color:$info;font-size: 20px;font-weight: 600;padding: 20px 0px;
			a {text-decoration: none;color:inherit;opacity: 1;}
		}
		p {font-size: 20px;color:lighten($color: $black, $amount: 25%); line-height: 35px;}
	}
	@media (max-width: 991px) and(min-width: 768px) {
		.caption { 
			h5 {font-size: 16px;}
			p{font-size: 16px;}
		}
	}
}
@media (max-width: 767px) {
	.service-box {margin-top:0px;margin-bottom:30px;		
		.caption { 
			h5 {font-size: 15px;}
			p{font-size: 15px;}
		}
	}
}
.tutor-btn {
	.btn-gradiant {background: transparent linear-gradient(179deg, $secondary 0%, $primary 100%) 0% 0% no-repeat padding-box;
		color:$white;text-transform: uppercase;font-size:15px;padding: 10px 30px;margin:15px 0px;font-weight:700;word-spacing: 6px;
	}
	&:before {content: url("/images/left-img1.png");float:left;}
	&:after {content: url("/images/right-img.png");float:right;}
	@media (max-width: 991px) and(min-width: 768px) {
		&:before {content:none ;}
		&:after {content: none;}
	}
	@media (max-width: 767px) {
		&:before {content:none ;}
		&:after {content: none;}
	}
}
.btn-purpul {background-color: $purpul;color:$white;font-weight: 700;
	&:hover {color:$white;}
}
.btn-info {padding: 10px 30px;font-size: 14px;text-transform: uppercase;font-weight: 600;}
.btn-primary{padding: 10px 30px;background: $primary 0% 0% no-repeat padding-box;font-size: 14px;text-transform: uppercase;}
.btn-link {padding: 6px 30px; background-color: $white;color:$primary;border:1px solid $primary;text-decoration:none;}
.form-control {height: calc(3rem + 4px);border-color: $gray-200;border-radius:4px;border:1px solid $gray-200;box-shadow: 0px 0px 5px #D5D5D5;}

.search-boxs { margin-bottom: 30px;background-color:#e7fbfc;padding: 20px;
	.form-row {margin-right: -5px; margin-left: -5px;}
	.form-group {margin-bottom: 0px; padding-left: 5px; padding-right: 5px; flex-basis: 0; flex-grow: 1; max-width: 100%;
	}
	
	::placeholder { color:$gray-300;font-size: 13px;}
	.btn {font-size: 16px; padding: 6px 25px;font-weight:700;}

	@media (max-width: 767px) {
        .form-group {flex-basis: auto;margin-bottom: 10px;}
	} 
}/*serch-boxs css end*/

.arrival-box {box-shadow: 0px 0px 6px #00000029;border-radius: 20px;opacity: 1; background-color: $white; margin-bottom: 30px; padding: 20px 0px 20px 0px; position: relative; 
	.image {
		img {width:170px;height: 170px;border:1px solid $gray-200;padding: 5px;border-radius: 50%;box-shadow: 0px 0px 5px #D5D5D5;}
	}
	.caption {
		h5 {font-size: 20px;color:$black;text-align:center;padding: 10px 20px;font-weight:600;
			a {color:inherit;text-decoration:none;}
		}
		h6 {font-size: 16px; text-align: center; padding: 10px 20px; font-weight: 600; height: 35px; margin-bottom: 5px;}
		.address {text-align: center; font-size: 14px; text-transform: capitalize; color: lighten($color: #000000, $amount: 20%);}
		.rating { line-height: 12px; justify-content: center;
			.star {
				.MuiRating-root {color:$yellow;}
			}
			.rate {margin-top: 5px; color: lighten($color: #000000, $amount: 49%);}
		}
		.badge-info { background: linear-gradient(110deg, $info 85%, $white 15%);padding:15px 30px 10px 15px;font-size: 14px;}
		.wishlist { position: absolute; top: 10px; right: 10px;
			i {box-shadow: 0px 0px 6px #00000029;color:$gray-500;padding:8px 7px 5px;border:1px solid $gray-200;border-radius: 50%;font-size:15px;margin-left: 2px;}
		}
		.favorite {
			.remove{font-size: 11px;}
		}
		.session-price{
			background-position: right top;background-repeat: no-repeat;background-size: cover; color: white; padding: 11px 18px 11px 12px;
		}
	}
}/*arival-box css end*/

.wishlist {
	i {box-shadow: 0px 0px 6px #00000029;color:$gray-500;border-radius: 50%;font-size:15px;margin-left: 2px;width: 33px;height: 33px;}
}

.post-box {box-shadow: 0px 0px 6px #00000029;border-radius: 15px;opacity: 1;background-color: $white;padding: 20px;
	.rating { display: flex; flex-wrap: wrap;
		.star {margin-right: 8px;
			.MuiRating-root {color:$yellow;}
		}
		.rate {color: lighten($color: #000000, $amount: 49%);}	
	}
}

.dashboard-layout {display: flex;
	&.bg-light {background-color: #F0F0F0 !important;}
	#left-side {width: 260px; float: left; background-color: $info; color: $white; position: relative; transition: all 0.3s ease-in-out; padding-top: 20px;
		.navbar-toggler {display: none;}
		.side-inner {width: 260px; position: relative;}
		.profile {padding: 10px 15px; position: relative;
			.username {margin-left: 15px;text-transform: uppercase;}
			.btn-toggle {float: right; transform: scale(1.9); padding: 0px 6px; color: $white; position: absolute; right: 5px; top: 16px; transition: all 0.3s ease-in-out;background:transparent;border:none;outline:none;box-shadow:none;}
		}
		.heading {padding: 10px 15px; text-transform: uppercase; background-color: #292828; color: #BFBFBF; font-size: 13px; transition: all 0.3s ease-in-out;}

	}
	.aside-menu {list-style: none; margin: 0px; padding: 0px;
		> li {
			> a {display: flex; align-items: center; padding: 15px 18px; border-bottom: 2px solid darken($gray-100, 70%); text-transform: uppercase; color: $white;position: relative;
				&:hover, &.active {text-decoration: none; background-color: $white; color:$gray-700;border-top-left-radius: 15px;border-bottom-left-radius:15px;margin-left: 8px;font-size: 13px;font-weight: 600;padding: 15px 10px;}
				.img {width: 30px; margin-right: 25px;
					img {width: 100%;height: 45px;}
					#count {background-color: $primary;color:$white;position:absolute;border:1px solid $primary;padding: 1px 5px;border-radius: 50%;font-size: 10px;margin-left: -13px;margin-top:5px;border-color:$white;}
				}
			}
		}
	}
	#right-side {width: calc(100% - 260px); float: left; padding: 40px 30px 30px; transition: all 0.3s ease-in-out; background-color: #F6FCFF;}
	&.toggle-layout {transition: all 0.3s ease-in-out;
		#left-side {width: 73px; overflow: hidden;
			.heading {height: 0px; padding: 0px;
				> span {opacity: 0;}
			}
			.profile {//width: 73px; 
				//.username {display: none;}
				.btn-toggle {top: 20px; right: 184px;outline:none;box-shadow:none;}
			}
		}
		#right-side {width: calc(100% - 73px);}
	}
	@media (max-width: 767px) {display: block;
		#left-side {display: block; width: 260px !important; position: fixed; z-index: 9999; left: -260px; top: 0px; overflow: scroll !important; height: 100vh;
			.navbar-toggler {display: block; position: fixed; right: 5px; z-index: 999999; top: 67px; background-color: #000; color: white;
			}
			.btn-toggle {display: none;}
		}
		#right-side {display: block; width: 100% !important; padding: 15px;}
		&.mobile-active {
			#left-side {left: 0px;
			}
		}
	}
} // Dashboard layout css end


.dashboard {
	.media {
		.image {
			img {border:1px solid $gray-200;padding: 5px;border-radius: 50%;box-shadow: 0px 0px 5px #D5D5D5;}
		}
		.media-body {
			h5 {font-size: 18px; font-weight: 700; text-decoration: none;
				a {color: inherit;}
			}
			.location { color:$gray-300;font-weight:600;margin-bottom: 0px;}
			.rating {line-height: 10px; display: flex; align-items: center; flex-wrap: wrap;
				.star {margin-right: 8px;}
			}
		} 
	}
	.inform {
		label {color:$primary;font-weight: 600;text-transform: capitalize;width:70px;}
		.date {color:$black;font-weight: 600;white-space: nowrap;}
	}
	.view {font-weight: 700;text-transform: uppercase;}
	.session {
		a {color:inherit;text-decoration:none;margin-bottom: 10px;line-height: 30px;}
	}
	.sub {
		.btn-light { padding: 6px 20px;background-color: #DFE3FF;color:$info; pointer-events: none;}
		.badge {padding: 14px; margin-left: 6px; color: $white;}
	}
	.review {
		a {color:$primary;text-decoration:none;font-size: 15px;font-weight: 700;}
	}
}//dashbord css end

.page-title {font-size: 28px;font-weight: 700;}
.page-subtitle {font-size: 20px;font-weight: 700;}

.input-box {height: calc(3rem + 4px);border-color: $gray-200 !important;border-radius:4px;border:1px solid $gray-200 !important;margin-top: 0px !important;
	margin-bottom: 0px !important;padding: 0.375rem 0.75rem !important;
	.MuiInput-underline{
		&:before{border-bottom: none !important;}
		&:after {border-bottom: none !important;}
		.MuiInputBase-input {padding: 10px 0 7px !important;}
	}
}
//bottom-slider
//membership sessions
.session-block {margin-bottom: 50px;
	.sessions {display: flex; justify-content: space-between; align-items: center;
		@media (min-width: 1260px) { min-width: 277px;}
			margin: 0 25px;box-shadow: 0px 0px 6px #00000029;
		border-radius: 40px;min-height: 71px;padding-left:25px;font-size: 15px;font-weight: 500;
		span {min-width: 61px; min-height: 55px;display: flex;justify-content: center;align-items: center;
			border-radius: 0px 40px 40px 0px;margin: 8px;font-size: 20px;font-weight: 700;box-shadow: 0px 0px 6px #00000029;background-color: #f9f7f7;}
	}
}
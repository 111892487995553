
@import "./sass/ladda";
@import "./sass/variables";
@import "./sass/mixins";
@import "./sass/utilities";
@import "./sass/custom";

body { font-family: 'Montserrat', sans-serif !important; line-height: 26px; background-color: transparent !important;} 
button:focus {outline: none;}
.all-content {@include full-width(); min-height: calc(77vh - 24px); padding-top: 58px;
  @media (max-width: 991px) {padding-top: 66px;}
  @media(max-width: 767px) {min-height: calc(23vh - 18px);}
  @media (max-width: 360px) {padding-top: 70px;}
}
.auth-all-content {@include full-width(); min-height: calc(100vh - 0px);}
/// Css apply only mizilla firefox
@-moz-document url-prefix() {
	.all-content {@include full-width(); min-height: calc(65vh - 15px);}
} // Css Apply only 
.inner-page {@include full-width(); padding: 30px 0px; }
@media (min-width: 1170px) {.container {max-width: 1200px;}}
.react-confirm-alert-body > h1 {font-size: 26px; font-weight: 600;}
.react-confirm-alert-overlay {z-index: 1309 !important;}
.app-loading {background-color: $primary;}
.app-loading {position: fixed; left: 0px; top: 0px; display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; width: 100%; float: left; z-index: 99;}
.loading {position: relative; z-index: 2; background: url('/images/loader.svg'); width: 100px; height: 100px;}

#header {background-color: transparent; box-shadow: none; z-index: 9;
	.navbar {background-color: $info !important; box-shadow: 0px 4px 4px rgba($info, 0.1); padding-top: 0px; padding-bottom: 0px;
        /*.navbar-brand img {width: 40px; transform: scale(1.5);}*/
        
    .navbar-brand  {padding: 12px;
        img {width: 125px;}
    } 

    .dropdown-notification {
      .nav-link {
        height: 100%;
        padding-top: 24px;
      }
    }
		.profile-dropdown {
      padding: 20px 8px;
      .dropdown-toggle {padding-top: 0px; padding-bottom: 0px; color: $white; font-size: 14px;
          &:after {content: none;}
          span.username {
            &:after {
              content: "\f078";
              position: relative;
              display: inline-block;
              padding-left: 8px;
              font-family: 'Font Awesome 5 Free';
              font-weight: 600;
              color: #FFF;
            }
          }
      }
      &:hover {
        background-color: #8f10ff;
        transition: all .3s ease-in-out;
        .dropdown-menu {
          /*display: block;*/
        }
      }
		}
		.navbar-main {
            > li {padding-right: 1px;}
			.nav-link {color: $white; font-weight: 400; padding: 30px 18px; transition: all 0.3s ease-in-out;word-spacing: 3px;text-transform:uppercase;
				&:hover, &.active {color: $white; background-color: $purpul;}
			}
    }
    .navbar-profile {
      > li {padding-right: 1px;}
      .nav-link {
        .badge{margin-left:-10px;vertical-align: top;border:2px solid $info;}
			}
    }
  }
   
	.navbar-login {margin: 0px 20px;
		li {
        .nav-link {color: $white; font-weight: 400; padding: 10px 18px; transition: all 0.3s ease-in-out;text-transform:uppercase;word-spacing: 3px;
				&:hover, &.active {color: $warning;}
			}
        }
    }
    @media (min-width: 992px) {
      .hide-desktop{ display: none;}
    }
    @media (max-width: 991px) {
      .hide-mobile{ display: none;}
    }

	@media (max-width: 1400px) {
		.navbar {
            .navbar-main {
                .nav-link {padding: 18px 14px;}
            }
        }
        .navbar-login {margin: 0px 8px;

        }
    }
    @media (max-width: 1200px) {
        .navbar {padding-right: 22px; padding-left: 0px;
            .navbar-main .nav-link {padding: 8px 14px;}
        }
        .navbar-nav .dropdown-menu {position:absolute;}
        .navbar-collapse {order: 6;}
        .navbar-profile{order: 0;flex-direction: row;}
        .navbar-login {margin: 0px; flex-direction: row; order: 2;}
        .navbar-toggler {order: 5;}
        .navbar-expand-xl {
            .navbar-nav {
                .dropdown-menu {position: absolute;}
            }
        }
        .navbar {
            .navbar-brand {width: auto;;
                img {width: 80px;}
            }
        }
        .navbar-dark {
            .navbar-toggler {padding: 0px;}
        }
    }
    @media (max-width: 600px) {
        .dropdown-notification {
          .dropdown-menu {min-width: 270px; right: -136px;}
          .nav-link {padding-top: 24px !important;}
        }
        .navbar {
          .navbar-profile {margin-left: auto; margin-right: 10px;}
          .profile-dropdown {
            padding-right: 0;
            span.username {display: none;}
            .dropdown-toggle:after {content: none;}
            .dropdown-menu { left: -100px; }
          }
          .dropdown-notification {margin: 0px 8px;
            &.dropdown > .nav-link {padding-top: 5px;
              img {width: 28px;}
            }
          }
        }
    }
    @media (max-width: 574px) {
        .navbar-social {display: none;}
    }
} /* Header css end */

.home-banner {position: relative;
  .image {position: relative;}
  .caption {position: absolute;width: 100%;height: 100%;left: 0;top:0;align-items: center;
    justify-content: center;margin-top: 170px;
    h2 {font-size: 50px; font-weight: 700;color:$white;margin-bottom: 30px;}
    h4 {font-size: 25px;color:$white;line-height: 30px;margin-bottom: 30px;}
    .search-box {margin-bottom: 30px;
      .form-row {margin-right: -5px; margin-left: -5px;}
      .form-group {margin-bottom: 0px; padding-left: 5px; padding-right: 5px; flex-basis: 0; flex-grow: 1; max-width: 100%;
      }
      .form-control {height: 59px; box-shadow: none; background-color: darken($color:$white, $amount: 4%);}
      ::placeholder { color:lighten($color: $black, $amount: 65%); font-size:14px;}
      .btn {font-size: 15px; padding: 18px 20px;font-weight:700;}
    }

  }
  @media (max-width: 991px) and(min-width: 768px) {
    .caption {margin-top:20px;
      h2{font-size: 30px;}
      h4{font-size: 18px;}
      .search-box {
        .btn {font-size: 12px;}
      }
    }
  }
  @media (max-width: 767px) {
    .caption {position: relative;margin-top:10px;
      h2{font-size: 25px;line-height: 40px;color:$info;margin-bottom: 10px;}
      h4{font-size: 20px;color:$info;line-height: 30px;}
      .search-box {
        .form-group {flex-basis: auto;margin-bottom: 10px;}
      }
    }
  }
}/*home banner css end*/

.learnning { background-color: $white;margin-top:100px;
  .learnning-box {background-color: $info;border:1px solid $info;border-radius: 20px;
    h2 {font-size: 40px; font-weight: 700;color:$white;margin: 30px;line-height: 60px;padding-bottom:30px;border-bottom: 3px solid $white;}
    .box {margin:20px 0px;
      h3 {color:$white;font-size: 30px;font-weight: 600;padding: 20px;
        a{color:inherit;text-decoration:none;}
      }
      .image {
        i {font-size:95px;color: $warning;}
      }
    }
  }
  @media (max-width: 991px) and(min-width: 768px) {
    .learnning-box {
      h2{font-size: 30px;}
    }
  }
}
@media (max-width: 767px) {
  .learnning {margin-top:0px;
    .learnning-box {
      h2{font-size: 25px;line-height: 40px;}
      .box {
        .image {
          img{ width: 35px;}
          i {font-size:32px;}
        }
        h3 {font-size: 16px;}
      }
    }
  }
}/*learnning css end*/

.tutors {
   h2 {font-size: 40px; font-weight: 700;color:$info;margin-bottom: 30px; }
   p{font-size: 14px;line-height: 28px;margin-bottom: 20px; color: lighten($color: $black, $amount: 50%);}
  .slick-slider{
    .slick-slide {
       div { margin: 0px 15px;}
      .subject { transition: .6s all ease-in; box-shadow: 0px 0px 6px #00000029;border-radius: 20px;opacity: 1;background-color: $white;padding: 43px 10px 38px;margin:2px;
        .image {border-radius: 50%;background-color: $white;box-shadow: 0px 3px 6px #00000029;display: flex;margin:0 auto; width: 90px; height: 90px;justify-content: center;align-items: center;
        }
        .title { transition: .6s all ease-in;font-size: 20px;font-weight: bold;text-transform: capitalize;margin-top: 20px;  color: #000; text-decoration: none;}
      }
      &:hover { 
        .subject {background-color: $info; transition: .4s all ease-in;
          .title {color:$white; transition: .4s all ease-in;} 
        }
      }
      // &.slick-current {
      //   .subject {background-color: $info;
      //     .title {color:$white;}
      //   }
      // }
    }
    .slick-prev {top:0;right: 20px;margin-top: -40px;font-size: 20px;left: auto;
      i {color:$info;font-size: 25px;}
    }
    .slick-next {top:0;margin-top: -40px;right: 0px;font-size: 20px;
      i {color:$info;font-size: 25px;}
    }
  }
    @media (max-width: 991px) and(min-width: 768px) {
      h2{font-size: 30px;}
    }
   @media (max-width: 767px) {
    h2{font-size: 25px;line-height: 40px;}
  }
}

.better-way {
  h2 {font-size: 40px; font-weight: 700;color:$info;margin-bottom: 30px;line-height: 60px;text-shadow: 0 0 $info; }
  .image { margin-left: -20px;}
    @media (max-width: 991px) and(min-width: 768px) {
      h2{font-size: 30px;line-height: 50px;}
    }
    @media (max-width: 767px) {
      h2{font-size: 25px;line-height: 40px;}
    }
}

.list-icon {list-style: none; padding-left: 0px; line-height: 50px; font-size: 15px;
	li {padding-left: 0px; width: 50%; float: left; color: $info;
    &:before {content: url("/images/check-list.png"); padding-right: 10px;float:left}
    p{margin-top: -5px;font-weight: 600; color: $primary;}
	}
	@media (max-width: 767px) {
		li {width: 100%;}
	}
} /* List icon css end */

.passion {
  h2 {font-size: 40px; font-weight: 700;color:$info;margin-bottom: 20px;line-height: 50px; }
  p{font-size: 16px;line-height: 40px;color:$gray-700;font-weight: 700;}
  @media (max-width: 991px) and(min-width: 768px) {
    .left{width: 50%;}
    h2{font-size: 30px;}
  }
  @media (max-width: 767px) {
    h2{font-size: 25px;line-height: 40px;}
  }
}

.best {
  h2 {font-size: 40px; font-weight: 700;color:$info;line-height: 50px;padding:50px 0px; }
  .service {background-color: $info;height: 200px;}
  @media (max-width: 991px) and(min-width: 768px) {
    h2{font-size: 30px;text-align: center;padding:0px 0px 20px 0px;}
  }

  @media (max-width: 767px) {
    h2{font-size: 25px;line-height: 40px;padding:0px 0px 20px 0px;text-align: center;}
    .service {display: none; }
  }
}

.talent {
  h2 {font-size: 40px; font-weight: 700;color:$info;line-height: 50px;text-align: center;margin-bottom: 30px; }
  .talent-list {list-style: none; padding-left: 0px; line-height: 50px; margin-top:30px;
    li {padding-left: 0px; width: 25%; float: left;margin-bottom:30px; 
      .talent-box {justify-content: center;text-align:center;
        .text {
          h5{font-size: 20px; font-weight: 700;color:$primary;line-height: 50px;
            a{text-decoration:none;color:inherit;}
          }
        }
        .caption {display: none;}
        &:hover{
          .caption {display: block;opacity: 0.6;position:absolute;margin-top: -193px;margin-left: 92px;
            h4{color:$white;font-size: 20px;text-transform:capitalize;
              a{text-decoration:none;color:inherit;}
            }
            h6 {color:$white;font-size: 16px;}
            .rating {
              i {color:$yellow;}
            }
          }
          .image {background:$black;margin:0px 40px;border-radius:50%;
            img {opacity: 0.4;width: 100%;}
          }
        }
      }
    } 
  }
  @media (max-width: 991px) and(min-width: 768px) {
    h2{font-size: 30px;}
    .talent-list {
      li {width: 33%;
        .talent-box {
          .image {
            img {width: 100px;height: 100px;}
          }
          .text {
            h5{font-size: 16px; }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    h2{font-size: 25px;line-height: 40px;}
    .talent-list {
      li {width: 50%;
        .talent-box {
          .image {
            img {width: 100px;height: 100px;}
          }
          .text {
            h5{font-size: 15px; }
          }
        }
      }
    }
  }
}/*talent section css end*/

.slider {
	.slick-slide {padding: 0px 15px;}
    .item {position: relative;
      .image {position: relative;}
      .homeSlider { border-radius: 45px;}
      .caption {position: absolute;width: 100%;bottom: 0;left:0;justify-content:center;
        h4 {
          color:$white;
          background-color: $warning;
          padding: 30px;
          border-bottom-left-radius: 44px;
          border-bottom-right-radius: 44px;
          margin-bottom: 0px;
          opacity: 0.8;
          font-weight:700;

          @media (max-width: 1200px) {
            font-size: 1rem;
          }
        }

      }
    }
  
  .slick-next {right:100px;width: 35px;height: 40px;
    img {width:20px;}
    &:before{display: none;}
  }
  .slick-prev {left: 70px;width: 30px;height: 70px;z-index: 1;
    img {width:20px;}
    &:before{display: none;}
  }
  @media (max-width: 767px) {
    .item {
      .caption{width: 100%;
        h4 {padding: 15px;border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
      }
    }
    .slick-next {right:60px;width: 30px;height: 50px;
      img {width:15px;}
      &:before{display: none;}
    }
    .slick-prev {left: 60px;width: 30px;height: 50px;z-index: 1;
      img {width:15px;}
      &:before{display: none;}
    }
  }
}//slider-bottom css end

.login-page {  background-position: right top; background-repeat:  no-repeat; background-size: contain;
  .login-box {
    h1{font-size: 45px;font-weight: 700;color:$info;}
    .form-check { padding-left: 0.25rem;
      label{ span{font-size: 12px;color:$info;font-weight:600;} margin-bottom: 0;}
    }
    .account  { color: lighten($color: $black, $amount: 49%); font-size: 15px; }
    small {font-size: 12px;color:$info;
      a{color:$info;font-weight:600;}
    }
    .btn-google {background-color:#4285F4; color:$white;padding: 12px 10px;width: 220px;margin-left:10px;text-transform:uppercase;font-weight:700;font-size:15px;position: relative;
      .img{
        // margin-right: 10px; width: 32px; height: 32px; background-color: white;border-radius:50%; display:flex; align-items: center; justify-content: center;
        width: 44px; height: 44px; background-color: white;display: flex;align-items: center;justify-content: center;position: absolute;left: 1px;border-radius: 5px;
        .icon {width: 22px;}
      }
    }
    .btn-facebook {padding: 12px 10px; width: 220px;margin-right:10px;text-transform:uppercase;font-weight: 700;font-size:15px;position: relative;
      .img{
        // margin-right: 10px; width: 32px; height: 32px; background-color: white;border-radius:50%; display:flex; align-items: center; justify-content: center;
        width: 44px; height: 44px; background-color: white;display: flex;align-items: center;justify-content: center;position: absolute;left: 1px;border-radius: 5px;
        .icon {width: 22px;}
      }
      &::before {content: ""; background-image: url(/images/logo-facebook.png); background-color: white; width: 45px; height: 100%; position: absolute; left: 0; background-repeat: no-repeat; background-size: 30%; background-position: 14px 10px; border-radius: 5px;}    
    }
    label{color:$info;font-weight: 700;font-size: 14px;}
    .form-control{
      &::placeholder {font-size: 13px;color:darken($gray-200, 9.5%);}
    }
    .text {color: lighten($color: $black, $amount: 49%);}
    .btn-link {color:$info;font-weight: 600;}
    .MuiFormGroup-root {
      flex-direction: row !important;
    }
    .MuiRadio-colorSecondary.Mui-checked {color: $primary;}
    .MuiRadio-root {color: $primary;}
    .MuiIconButton-colorPrimary {color: $info;}
  }
  .image {
    img {width: 100%;
    height: 100%;}
  }
  @media (max-width: 991px) {
    .login-box {
      h1{font-size: 30px;}
      .btn-facebook {margin-right: 10px; padding: 6px 28px;}
      .btn-google {margin-left: 10px; padding: 6px 35px;}
    }
  }
  @media (max-width: 375px) {
    .login-box {
      .btn-facebook {margin-right: 10px; padding: 6px 22px;}
      .btn-google {margin-left: 10px; padding: 6px 22px;}
    }
  }
}/*login page css end*/

.listing-page {
  .title {color:$gray-300;margin-bottom: 30px;text-transform: capitalize;}
}
.view-page {
  .view-detail {
    .image {
      img {width:170px;height: 170px;border:1px solid $gray-200;padding: 5px;border-radius: 50%;}
    }
    h3 { font-size: 20px; font-weight: 700;color:$gray-400; }
    h6 {font-size: 15px;padding: 10px 0px;}
    .favorite {
      i {color:$gray-500;}
    }
    .location { color:$gray-300;font-weight:600;margin-bottom: 15px;}
    .sub {
      h5 {font-size: 16px;font-weight: 600;}
      .badge-light {margin-right: 10px;color:$info;font-size: 13px;padding: 6px 20px;background-color:#DFE3FF;}
    }
    .book {margin-top: 20px;
      i {cursor: pointer;}
    }
    .wishlist {
			i{ color:$gray-500;padding:8px 5px 5px;border:1px solid $gray-200;border-radius: 50%;}
			&:hover{
				i{color:$primary;}
			}
    }
    .msg-btn {background-color: $purpul;text-transform: uppercase;font-weight: 600;color:$white;padding: 6px 25px;
      font-size: 14px;}
    .btn-info{font-size: 14px;}  
  }
  .detail {
    .heading {color:$primary;font-size: 20px;padding: 20px;border-bottom: 1px solid $gray-200;font-weight:600;
        .subtitle {color:$primary;font-size: 20px;}
        .name {color:$gray-700;font-size: 14px;}
    }
    .category {padding: 20px;
      h4{font-size: 15px;margin-right:30px;text-transform: capitalize;}
    }
    .text{font-size: 14px;color:$gray-500;}
    .list-group {border-radius: 0rem;
      .list-group-item {border:1px solid $gray-200;border-top:none;
        .left-site {width: 20%;float: left;border-right:2px solid $gray-200;margin-right:30px;
          img {width: 50px; height: 50px;border-radius: 50%;}
          h5 {font-size: 16px;color:$info;font-weight:600;text-transform: capitalize;
            a {color:$info;}
          }
        }
        .right-site {
          .reply {font-weight: 600;text-transform: uppercase;font-size: 15px;}
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .detail {
      .list-group {
        .list-group-item {
          .left-site {width: 35%;}
        }
      }
    }
  }
  @media (max-width: 767px) {
    .view-detail {
      .media {display: block;
        .image {justify-items: center;margin: 0 15%;margin-bottom: 20px;}
      }
    }
    .detail {
      .list-group {
        .list-group-item {
          .left-site {width: 100%;float:none;border-right:0px;}
        }
      }
    }
  }
}/*view page css end*/

.book-tab {
  ul {
    li {list-style: none;
      .list-group-item {color:$black;font-size:20px; font-weight: 700; border:none;border-radius: 10px;text-decoration:none;
      
        &.active{ background-color: $primary;color:$white; border-radius: 10px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    ul {
      li {
        .list-group-item { font-size: 16px;}
      }
    }
  }
  @media (max-width: 375px) {
    ul {
      li {
        .list-group-item { font-size: 13px;}
      }
    }
  }
}//css end

.check-card {
  .custom-control-label::before {top:1rem;}
  .custom-control-label::after {top:1rem;}
}

.master-card {
  .card {border:none;background-color: none;
    .card-header {background-color:transparent;border-bottom: none;}
    .card-body {
      label {color:$info;}
      ::placeholder {color:$gray-300;font-size: 13px;}
    }
  }
}// master card css end

.table {border-collapse: separate;border-spacing: 0 20px;padding: 0px 5px;
  thead {background-color:#E7FBFC;
    tr{box-shadow: 0px 0px 6px #00000029;border-radius: 15px;opacity: 1;
      padding: 20px;
      th{background-color: transparent;border-bottom:none;border-top:none;color:$info;padding:15px 20px;}
    }
  }
  tbody {
    tr {box-shadow: 0px 0px 6px #00000029;border-radius: 15px;opacity: 1;
      padding: 20px;margin-bottom: 10px;
      td {background-color: transparent;border-top:none;color:$gray-300;padding:15px 20px;}
    }
  }
} //table css end

.step-box{border-radius: 10px;box-shadow: 0px 0px 6px #00000029;padding: 0px;border:1px solid $gray-200;background:$white;margin-bottom:20px;
 
  .list-group{border-radius: 10px;
    .list-group-item{font-weight: bold;text-transform: uppercase;font-size: 14px;padding: 15px 20px;color:$black;position:relative;border-right:none;border-top:none;
      &.active, &:hover {border-left: 3px solid $primary;color:$primary;background-color: transparent;border-top:none;border-right:none;border-bottom:1px solid rgba(0, 0, 0, 0.125);padding: 15px 18px;
        border-top-left-radius: 0px;border-bottom-left-radius: 0px; }
    }
  }
}//left tab css end

.heading {color:$black;font-size: 20px;padding: 20px 20px 15px 20px;border-bottom: 1px solid $gray-200;font-weight:600;}

.setting-form, .update-profile {padding: 20px;
  label {color:$info;font-weight: 600;}
  .form-control {font-size: 13px;
    &::placeholder {color:$gray-500;font-size: 13px;}
  }
  
  .edit-button{margin-top: 32px;
    .btn{margin:0px 10px;}
  }
  .add-button {margin-top: 27px;
    .btn-primary {padding: 4px 20px;height: 40px;}
    .btn-link {padding: 4px 20px;height: 40px;color:$gray-700;}
    .btn-add {
      i {background-color: $purpul;padding:10px;border-radius: 50%;color:$white;}
    }
  }
  .custom-file {margin-bottom: 150px;
    .custom-file-label {width: 140px;height: 140px; border-radius: 50%;border: 1px solid $gray-200;
        i {position: absolute; width: 40px;height: 40px;bottom: 0;margin-left: 40px;border: 1px solid $primary;border-radius: 50%;padding: 12px; background-color: $primary;color:$white;}
    }
    .custom-file-label::after {display: none;}
  }
}//setting form css end

.notifications {
  .list-group{border-radius: 0rem;
    .list-group-item{margin-bottom: 20px;box-shadow: 0px 0px 6px #00000029;border-radius: 15px;opacity: 1;
      padding: 20px;
      h5{font-size: 15px;color:$gray-300;margin-bottom: 0px;margin-top:10px;
        a{color:inherit;text-decoration: none;}
      }
      p {margin-bottom: 0px; display: flex;
          span {
              &:first-child {min-width: 125px;margin-top:8px;}   
          }
      }
      &:hover{background-color:#F9F9F9;border-left: 0px solid inherit ; }
    }
  }
}//notification css end

.faq {
  .search-form {background-color: #e7fbfc;padding: 20px;margin-bottom:20px;
    h4 {margin:12px 15px;font-size: 27px;}
    .form-control {border-right-color:transparent;}
    ::placeholder {color:$gray-500;font-size: 13px;}
    .input-group-append {
      .btn {border-left: none;}
    }
  }
  h3{font-size: 20px;font-weight: 700;margin-bottom: 20px;}
  .panel {border-bottom: 1px solid $gray-200; border-top: 1px solid $gray-200; padding: 0px 0px; margin-bottom: 20px; outline: none;

    .panel-title {font-size: 15px; padding: 15px ; margin-bottom: 0px;color: $black;font-weight:700;outline:none;
        i{color:$black;margin-right: 15px;}
        a{text-decoration:none;}
        .btn-link {border:none;
          &:focus{outline: none;border:none;}
        }
    }
 }
 .panel-body {font-size: 15px;color: $text;padding: 0px 10px 0px 40px;}
 
 @media (max-width: 767px) {
  .search-form {
    h4{font-size: 23px;}
  }
 }
 @media (max-width: 360px) {
  .search-form {
    h4{font-size: 18px;}
  }
 }
}// faq page css end

.about-page {
  .about-banner  {margin-bottom: 60px;
    .image {width: 100%;height: 600px;
      img {width: 100%;height: 100%;}
    }
    .caption {position: absolute;width: 100%;height: 100%;left: 0;top:0;margin-top:10%;
        h2 {font-size: 40px;color:$info;font-weight: bold;line-height: 60px;margin-bottom: 30px;}
        p {font-size: 16px; line-height: 40px; color: #495057; font-weight: 700;max-width: 610PX;}
    }
  }
}//about-page css end

/*.messages-page { margin-top: 0px; margin-bottom: -30px; color: $gray-300;
	.left-side { padding-top: 20px; margin-left: 0px;
		h3 {font-size: 18px; padding-bottom: 10px; padding-left: 30px;}
		.input-group {margin-bottom: 20px; padding-right: 30px; padding-left: 30px;
			.form-control {border-left-color: transparent}
			.btn {background-color: transparent; border-right-color: $white;border-color: $gray-200; font-size: 19px;margin-right:-2px;padding: 11px;}
		}
		.list-group {margin-left: 0px; margin-bottom: 30px;
			.list-group-item {border-radius: 0px; border-left: none; border-right: none; color: $gray-300; position: relative; transition: all 0.3s ease-in-out; overflow: hidden;
				&:first-child, &:last-child {border-radius: 0px;}
				&:last-child {border-bottom: none;}
				.d-flex {position: relative; }
				&.active, &:hover, &:focus {background-color: #e7fbfc; border-color: rgba(0,0,0,.125); transition: all 0.3s ease-in-out;
				}
				.text {float: left; width: 80%;}
				.rounded-circle {border: 1px solid $gray-200;width: 50px;}
				h4 {font-size: 16px; color: $black; font-weight: 600;}
				p {margin-bottom: 0px; font-size: 14px; color: $gray-300; }
				.count {width: 30px; height: 30px; background-color: $primary; color: $white; border-radius: 100%; text-align: center; position: absolute; right: -14px; top: 20px; font-weight: 600; padding-top: 6px;}
			}
		}
	}
	.message-list { padding: 30px 0px;
		ul {padding: 0px;
			li { margin-bottom: 20px; padding-right: 130px;
				.profile {text-align: center; margin-right: 20px; width: 125px;
					.rounded-circle {border: 1px solid $gray-200;width: 50px;}
					time {width: 100%; float: left; padding-top: 3px; color: $gray-300; font-size: 12px;}
				}
				.text {float: left; border: 1px solid $gray-200; padding: 15px; color: $gray-300;}
				&:nth-child(even) {padding-right: 0px; padding-left: 130px;
					.profile {order: 2; margin-right: 0px; margin-left: 20px;}
					.text {color: $gray-300; background-color: #e7fbfc; border-color: #e7fbfc;}
				}
			}
		}
	}
	.message-button { padding-bottom: 25px;
		.btn-toolbar {padding-top: 20px;}
		textarea {height: 100px;}
		.btn-sm {border-radius: 0px; font-size: 22px; padding: 0rem 0.5rem;}
	}
	@media (max-width: 1440px) {
		.col-sm-3.large-desktop {flex: 0 0 30%; max-width: 30%;}
		.col-sm-9.large-desktop {flex: 0 0 70%; max-width: 70%; padding-right: 0px; padding-left: 0px;}
		.left-side .list-group .list-group-item {
			.pic {margin-right: 6px !important;}
		}
		.message-list ul li .profile {margin-right: 15px; width: 60px;}
		.message-list ul li:nth-child(2n) .profile {margin-right: 5px; margin-left: 15px;}
	}
	@media (max-width: 1199px) {
		> .row {margin: 0px;}
	}
	@media (max-width: 991px) {
		.col-sm-3.large-desktop {flex: 0 0 100%; max-width: 100%; padding-right: 0px;}
		.col-sm-9.large-desktop {flex: 0 0 100%; max-width: 100%; padding-right: 0px; padding-left: 0px;}
	}
	@media (max-width: 640px) {
		.message-list ul li {margin-bottom: 15px; padding-right: 10px;}
		.message-list ul li:nth-child(2n) {padding-left: 10px;}
		.message-button form {display: block !important;
			textarea {margin-right: 0px; margin-bottom: 10px;}
			.btn {width: 100%;}
		}
		.message-button .btn-toolbar {padding-top: 10px;}
	}
} // Message page css end */

.approve {
  i{color:$purpul;}
}
.membership-box {background-color: $white;box-shadow: 0px 0px 6px #00000029;border-radius: 20px;border:1px solid $gray-200;
  h6 {padding:20px;font-size: 20px;font-weight: 700; background-color: darken($primary, 20%); border-top-right-radius: 20px;
    border-top-left-radius: 20px;margin-bottom: 0px;}
  
  .title {
    width: 100%;color:$white;padding-top: 20px;margin-bottom: 0px;text-align:center;font-weight: 700;
    background: darken($primary, 10%);position: relative;display: inline-block;
    
  &:after {
    content: "";
    position: absolute;
    bottom: -27.8675px;
    left: 0;
    width: 100%;
    height: 0;
    border-left: 135px solid transparent;
    border-right: 135px solid transparent;
    border-top: 28.8675px solid darken($primary, 10%);
  }
  small { font-size: 15px;}
}

  .detail {position:relative;margin-top:50px;
    .list {margin-bottom: 20px;margin-left:40px;
      p {font-size: 14px;font-weight:600;
        i{color:$primary;}
      }
    }
  }
  &.blur {filter: blur(4px); pointer-events: none;}
  @media (max-width: 767px) {
    
  }
  &.other-1, &.other-2, &.other-3 {
    h6 {background-color: darken($secondary, 10%);}
    .title {background-color: $secondary;}
    .title:after {border-top-color: $secondary;}
  }
  &.other-4, &.other-5, &.other-6 {
    h6 {background-color: darken($purpul, 10%);}
    .title {background-color: $purpul;}
    .title:after {border-top-color: $purpul;}
  }
  &.other-7, &.other-8, &.other-9 {
    h6 {background-color: darken($primary, 10%);}
    .title {background-color: $primary;}
    .title:after {border-top-color: $primary;}
  }
}//membership page css end

.content-page {
  p{ line-height: 28px;color:$gray-300;font-size: 15px;
    strong{font-size: 16px;font-weight:bold;color:$black;}
  }
}//content page css end

.step-wizard {margin-top: 0px;width:100%;margin-bottom:20px;
  .WithStyles\(ForwardRef\(StepConnector\)\)-alternativeLabel-8 {left: calc(-60% + 16px) !important;right: calc(42% + 16px) !important;
  }
  .WithStyles\(ForwardRef\(StepConnector\)\)-active-9 .WithStyles\(ForwardRef\(StepConnector\)\)-line-11 {
    border-color: #F6921E;
  }
  .WithStyles\(ForwardRef\(StepConnector\)\)-completed-10 .WithStyles\(ForwardRef\(StepConnector\)\)-line-11{
    border-color: #F6921E;
  }
  .WithStyles\(ForwardRef\(StepConnector\)\)-line-11 {border-color: #7D7C7C;}
  .MuiStepLabel-label.MuiStepLabel-completed {color:$info;}
  .MuiStepLabel-label.MuiStepLabel-active {color:$info;font-weight:600;}
  .MuiStepLabel-label {color:$info;}
  .makeStyles-circle-6 {display: none;}
  .makeStyles-active-5 {content: url("/images/svg/step.svg");float:right;margin-top: 0px;}
  .makeStyles-completed-7 {display: none;}
  @media (max-width: 1200px) {
    .WithStyles\(ForwardRef\(StepConnector\)\)-alternativeLabel-8 {left: calc(-100% + 16px) !important;right: calc(30% + 16px) !important;
    }
    .MuiStepper-root {padding:0px;}
  }
  @media (max-width: 767px) {
    .WithStyles\(ForwardRef\(StepConnector\)\)-alternativeLabel-8 {left: calc(-100% + 10px) !important;right: calc(10% + 10px) !important;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {font-size: x-small;}
  }
  @media (max-width: 360px) { 
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {font-size: 7px;}
  }
}//step-wizard form css end

.background-page {
  .experience {
    .card-title { color:$primary;font-weight: bold;font-size: 20px;padding: 0px 20px;}
    .list-group {
      .list-group-item {border:none;
        h6 {font-size: 18px; font-weight: bold;margin-bottom: 15px;text-transform: capitalize;}
        .text {color:$gray-300;font-size: 15px;line-height: 28px;text-align: justify;margin-right: 15px;}
        .button {
          .btn{float: left;position: relative;}
        }
      }
    }
  }
}//background-page css end

.MuiPagination-root {
	.MuiPagination-ul{margin-top:20px;
		li {
			.MuiPaginationItem-textPrimary{
				&.Mui-selected {background-color: $info;}
				&.MuiPaginationItem-root {height: 25px;min-width: 25px;padding: 0px;} 
			}
			&:first-child > button, &:last-child > button {width: 125px; height: 25px; text-transform: uppercase; font-size: 14px; font-weight: 500; background: url("/images/btn-sm.png") no-repeat; color: $white; position: relative;
				> svg {display: none;}
				&:hover {color: $info; }
			}
			&:first-child > button:before {content: "Prevous"; color: $black;font-weight: 700;}
			&:last-child > button:before {content: "Next"; color: $black;font-weight: 700;}
		}
	}
}//pagination css

.messages-page {display: flex; flex-wrap: wrap; margin-left: -15px; margin-right: -15px;
	#msg-left {width: calc(100% - 350px); padding-left: 15px; padding-right: 15px;}
	#msg-right {width: 350px; padding-left: 15px; padding-right: 15px;}
	.messages {background-color: $white; box-shadow: 0px 0px 6px #00000029; border-radius: 20px;
		.header {border-top-left-radius: 20px; border-top-right-radius: 20px; display: flex; align-items: center; padding: 10px 15px; border-bottom: 1px solid $gray-200; display: flex; align-items: center;
			img {margin-right: 8px; width: 40px;}
			h5 {margin: 0px; font-weight: 600; font-size: 16px;}
		}
		.msg-list {min-height: 372px; max-height: 372px; padding: 10px; overflow-y: scroll; overflow-x: hidden;
			ul {padding: 0px; margin: 0px; list-style: none;
				.title {display: flex; align-items: center; justify-content: space-between; margin-bottom: 4px;
					.name {font-weight: 600; font-size: 14px;}	
					time {font-size: 80%;}
				}
				.text {background-color: #E7FBFC; padding: 5px 10px; border-radius: 4px; font-size: 13px; line-height: 22px;}
				li {margin-bottom: 12px; padding-right: 20px;
					&.me {padding-left: 20px; padding-right: 0px;
						.title .name {order: 2;}
						.text {background-color: $white; border: 1px solid #D8D5D5;}
					}
				}
			}
		}
		.footer {border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top: 1px solid $gray-200; padding: 0px 10px 0px 0px;
			form {display: flex; align-items: center;}
			.form-control {border-bottom-left-radius: 20px; border: none;}
		}
    }
    @media (max-width: 1024px) {
        #msg-left {width: 100%;margin-bottom: 30px; }
	    #msg-right {width: 100%;}

}
} // 
//contact-us
.contact-us {
  background-position: right center;
}

.cookies {position: fixed; left: 0px; bottom: 0px; background-color: $info; padding: 10px; z-index: 999; color: $white;}

.videos-start {width: 100%; margin-bottom: 30px;
	h2 {display: none;}
	
}

.content-page.tutor > div.py-5 {
  padding-top: 0 !important;
}
.content-page.tutor > div.py-5  > .container {
  max-width: none !important;
  padding: 0 !important;
}
.content-page.tutor > div.py-5  > .container > .row {
  margin: 0 0 0 0 !important;
}
.content-page.tutor > div.py-5  > .container > .row > .col {
  padding: 0 0 0 0 !important;
}
.content-page.tutor > div.py-5  > .container h2.text-left {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.tutor-landing {
  display: block;
  width: 100%;
}
.tutor-landing section {
  padding: 51px 0;
}

.tutor-landing section .wrapper {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
}
.tutor-landing section .cta,
.tutor-landing section .bg {
  position: relative;
  width: calc(50% - 4px);
}
.tutor-landing section .bg img {
  width: 100%;
  height: auto;
}

.tutor-landing section.hero {
  position: relative;
  margin-bottom: 32px;
  padding: 168px 0;
  background-color: #2b388f;
  color: #FFF;
}
.tutor-landing section.hero::before {
  content: "";
  position: absolute;
  display: block;
  bottom: -48px;
  width: 100%;
  height: 49px;
  background-color: #2b388f;
  clip-path: polygon(0 0%, 100% 0, 0% 50%, 0 100%);
}
.tutor-landing section.hero h1 {
  font-size: 50px;
  font-weight: 600;
}
.tutor-landing section.hero h1 span {
  display: block;
}
.tutor-landing section.hero p {
  margin-bottom: 60px;
  font-size: 24px;
  color: #FFF;
}
.tutor-landing section.hero .btn {
  padding: 16px 30px;
  font-size: 15px;
  font-weight: 600;
}

.tutor-landing section.lr-info {
  padding: 51px 0 72px 0;
}
.tutor-landing section.lr-info h2 {
  margin-bottom: 48px;
  font-size: 40px;
  font-weight: bold;
  color: #2b388f;
}
.tutor-landing section.lr-info h2 span {
  display: block;
}
.tutor-landing section.lr-info h4 {
  margin-top: 42px;
  font-size: 18px;
  font-weight: bold;
  color: #2b388f;
}
.tutor-landing section.lr-info h5 {
  margin-top: 4px;
  font-weight: bold;
  color: #495057;
}
.tutor-landing section.lr-info h5 span {
  color: #d01262;
}
.tutor-landing section.lr-info p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.tutor-landing section.how-it-works {
  position: relative;
  margin-bottom: 260px;
  padding: 104px 0 220px 0;
  background-color: #2b388f;
  color: #FFF;
}
.tutor-landing section.how-it-works .wrapper {
  max-width: 950px;
}
.tutor-landing section.how-it-works h2 {
  margin-bottom: 44px;
  font-size: 40px;
  font-weight: bold;
  color: #FFF;
  text-align: center;
}
.tutor-landing section.how-it-works p {
  font-size: 20px;
  color: #FFF;
}
.tutor-landing section.how-it-works ol {
  position: absolute;
  width: 100%;
  max-width: 1160px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 28px;
  margin: 72px 0 0 -105px;
  padding: 0;
  list-style-type: none;
}
.tutor-landing section.how-it-works ol li {
  counter-increment: k12-how-it-works;
  width: 100%;
  max-width: calc(25% - 28px);
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  background-color: #FFF;
  color: #000;
}
.tutor-landing section.how-it-works ol li::before {
  content: counter(k12-how-it-works);
  display: block;
  width: 78px;
  height: 78px;
  margin-bottom: 24px;
  padding-top: 8px;
  border-radius: 100%;
  background-color: #2b388f;
  color: #06edff;
  font-family: "Montserrat", sans-serif;
  font-size: 41px;
  font-weight: 900;
  text-align: center;
}
.tutor-landing section.how-it-works ol li > strong {
  display: block;
  margin-bottom: 20px;
  font-size: 23px;
  font-weight: bold;
}
.tutor-landing section.how-it-works ol li > em {
  display: block;
  font-style: normal;
  font-size: 16px;
}

.tutor-landing section.lr-apps .cta {
  width: calc(75% - 4px);
  padding-right: 24px;
}
.tutor-landing section.lr-apps .apps {
  width: calc(25% - 4px);
}
.tutor-landing section.lr-apps h2 {
  max-width: 500px;
  margin-bottom: 56px;
  font-size: 30px;
  font-weight: bold;
  color: #2b388f;
}
.tutor-landing section.lr-apps p {
  max-width: 780px;
  font-size: 16px;
  color: #000;
}
.tutor-landing section.lr-apps a.app-button {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 45px;
  padding: 20px 24px;
  border-radius: 8px;
  background-color: #2b388f;
  color: #FFF;
  text-decoration: none;
}
.tutor-landing section.lr-apps a.app-button::after {
  content: "";
  position: absolute;
  display: block;
  width: 34px;
  height: 34px;
  top: 50%;
  right: 24px;
  margin-top: -17px;
  background-size: cover;
}
.tutor-landing section.lr-apps a.app-button.google::after {
  background-image: url('/images/img-landing/google-play.png');
}
.tutor-landing section.lr-apps a.app-button.apple::after {
  background-image: url('/images/img-landing/app-store.png');
}
.tutor-landing section.lr-apps a.app-button em {
  display: block;
  font-size: 17px;
  font-weight: bold;
  color: #c1c5d9;
  font-style: normal;
}
.tutor-landing section.lr-apps a.app-button span {
  display: block;
  margin-top: -4px;
  font-size: 24px;
  font-weight: bold;
  color: #FFF;
}

.tutor-landing section.separator {
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  margin: 16px 0;
  padding: 0;
  background-color: #06edff;
}
.tutor-landing section.separator::before {
  position: absolute;
  content: "";
  display: block;
  width: calc((100% - 1385px) / 2);
  height: 7px;
  top: -1px;
  left: calc(1160px + 112px + (100% - 1160px) / 2);
  right: 0;
  background-color: #FFF;
}
.tutor-landing section.separator.right::before {
  right: calc(1160px + 112px + (100% - 1160px) / 2);
  left: auto;
}
.tutor-landing section.separator::after {
  position: absolute;
  content: "";
  display: block;
  width: 109px;
  height: 66px;
  left: calc(1160px + 110px + (100% - 1160px) / 2);
  top: -31px;
  background-image: url('/images/img-landing/separator-globe-2.jpg');
  background-size: cover;
}
.tutor-landing section.separator.right::after {
  right: calc(1160px + 110px + (100% - 1160px) / 2);
  left: auto;
  transform: scaleX(-1);
}

.tutor-landing section.lr-scenario {
  padding: 120px 0;
}
.tutor-landing section.lr-scenario + section.lr-scenario {
  padding-top: 0;
}
.tutor-landing section.lr-scenario .cta {
  width: calc(56% - 4px);
  padding-right: 48px;
}
.tutor-landing section.lr-scenario .cta h2 {
  margin: -32px 0 42px 0;
  font-size: 40px;
  font-weight: bold;
  color: #2b388f;
}
.tutor-landing section.lr-scenario .cta p {
  font-size: 16px;
  font-weight: 400;
}
.tutor-landing section.lr-scenario .example {
  width: calc(44% - 4px);
  padding: 54px 44px;
  border-radius: 8px;
  background-color: #2b388f;
  color: #FFF;
}
.tutor-landing section.lr-scenario .example h5 {
  margin-bottom: 32px;
  font-size: 22px;
  font-weight: bold;
}
.tutor-landing section.lr-scenario .example p {
  font-size: 16px;
  font-weight: 400;
  color: #FFF;
}

.tutor-landing section.footer-cta {
  margin: 48px 0;
}
.tutor-landing section.footer-cta .wrapper {
  position: relative;
  border-radius: 16px;
  background-color: #2b388f;
  color: #FFF;
}
.tutor-landing section.footer-cta .cta {
  padding-left: 24px;
}
.tutor-landing section.footer-cta .cta h2 {
  margin-bottom: 40px;
  font-size: 41px;
  font-weight: bold;
}
.tutor-landing section.footer-cta .cta .btn {
  padding: 16px 30px;
  font-size: 15px;
  font-weight: 600;
}
.tutor-landing section.footer-cta .bg img {
  position: relative;
  bottom: -48px;
}

@media all and (max-width: 1384px) {

  .tutor-landing section.separator::before {
      display: none;
  }
  .tutor-landing section.separator::after {
      right: 0;
      left: auto;
  }
  .tutor-landing section.separator.right::after {
      left: 0;
      right: auto;
  }

}

@media all and (max-width: 1224px) {

  .tutor-landing section .wrapper {
      margin-left: 0;
      margin-right: 0;
      padding-left: 32px;
      padding-right: 32px;
  }

  .tutor-landing section.lr-info h2 span {
      display: inline;
  }

  .tutor-landing section.how-it-works .wrapper {
      max-width: 1160px;
  }
  .tutor-landing section.how-it-works ol {
      margin-left: 0;
      width: calc(100% - 64px);
      justify-content: space-between;
      gap: unset;
  }    

}

@media all and (max-width: 1023px) {

  .tutor-landing section h2 {
      font-size: 32px !important;
  }

  .tutor-landing section .wrapper {
      flex-wrap: wrap;
  }
  .tutor-landing section .cta,
  .tutor-landing section .bg {
      width: 100%;
  }

  .tutor-landing section.hero {
      margin-top: -4px;
      padding: 48px 0;
      text-align: center;
  }
  .tutor-landing section.hero h1 {
      font-size: 36px;
  }    
  .tutor-landing section.hero h1 span {
      display: inline;
  }
  .tutor-landing section.hero p {
      margin-bottom: 32px;
      font-size: 22px;
  }
  .tutor-landing section.hero .btn {
      font-size: 15px;
  }
  .tutor-landing section.hero .bg {
      padding: 32px 0;
  }
  .tutor-landing section.hero .bg img {
      max-width: 400px;
  }

  .tutor-landing section.lr-info h2 span {
      display: block;
  }
  .tutor-landing section.lr-info .cta {
      order: -1;
  }
  .tutor-landing section.lr-info .bg {
      text-align: center;
  }
  .tutor-landing section.lr-info .bg img {
      max-width: 400px;
  }

  .tutor-landing section.how-it-works {
      margin-bottom: 0;
      padding-bottom: 104px;
  }
  .tutor-landing section.how-it-works ol {
      position: relative;
      flex-wrap: wrap;
      width: 100%;
  }
  .tutor-landing section.how-it-works ol li {
      width: 100%;
      max-width: none;
      margin: 8px 0;
  }
  .tutor-landing section.how-it-works ol li::before {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      width: 48px;
      height: 48px;
      padding-top: 1px;
      font-size: 32px;
  }
  .tutor-landing section.how-it-works ol li strong {
      display: inline-block;
      vertical-align: middle;
  }
  .tutor-landing section.how-it-works ol li em {
      display: block;
  }

  .tutor-landing section.lr-apps .cta {
      width: 100%;
  }
  .tutor-landing section.lr-apps .apps {
      width: 100%;
      padding: 16px 0;
      text-align: center;
  }
  .tutor-landing section.lr-apps a.app-button {
      display: inline-block;
      max-width: 300px;
      margin-bottom: 16px;
      margin-right: 16px;
      text-align: left;
  }

  .tutor-landing section.lr-scenario .cta {
      width: 100%;
      margin-bottom: 32px;
      padding-right: 0;
  }
  .tutor-landing section.lr-scenario .example {
      width: 100%;
  }

  .tutor-landing section.footer-cta .wrapper {
      border-radius: 0;
      padding: 32px 0;
  }
  .tutor-landing section.footer-cta .cta {
      padding: 32px 0;
      text-align: center;
  }
  .tutor-landing section.footer-cta .cta h2 {
      font-size: 32px;
  }
  .tutor-landing section.footer-cta .cta .btn {
      font-size: 15px;
  }
  .tutor-landing section.footer-cta .bg img {
      display: block;
      width: 50%;
      bottom: auto;
      margin: 0 auto;
  }

}

@media all and (max-width: 730px) {

  .tutor-landing section h2 {
      font-size: 28px !important;
  }

  .tutor-landing section.hero h1 span {
      display: block;
  }

  .tutor-landing section.lr-info h2 span {
      display: inline;
  }

}